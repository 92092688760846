import url from 'url';
import { parseBoolean } from '@tunein/web-utils';
import flow from 'lodash/flow';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { PERFORM_REDIRECT_WITH_USER_DATA } from 'src/common/constants/auth/redirects';
import withAuthHandling from '../../decorators/auth/withAuthHandling';
import withAuthViewHandling from '../../decorators/auth/withAuthViewHandling';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import clientRedirect from '../../utils/clientRedirect';
import {
  findProductBySku,
  getSelectedSku,
} from '../../utils/subscription/findProductHelpers';
import { excludeUtmParamsFromUrl } from '../../utils/utmCampaignParams';
import AuthViewContainer from './AuthViewContainer';
import css from './auth-page.module.scss';

class AuthPage extends Component {
  static propTypes = {
    productsObj: PropTypes.object,
    // from withAuthHandling
    authActions: PropTypes.object.isRequired,
    // from withAuthViewHandling
    history: PropTypes.object.isRequired,
    loginDetails: PropTypes.object.isRequired,
    viewType: PropTypes.string.isRequired,
    pageTitle: PropTypes.string,
    source: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    const products = map(props.productsObj, (product) => product);

    this.state = {
      products,
    };
  }

  componentWillUnmount() {
    this.props.authActions.authDone();
  }

  render() {
    const { authActions, loginDetails, source, viewType, history, pageTitle } =
      this.props;

    const { products } = this.state;
    const { location } = this.context;

    const querySku = getSelectedSku(products, location);
    const queryProduct = findProductBySku(products, querySku);

    return (
      <div className={css.container}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <AuthViewContainer
          key="authContainerPageView"
          queryProduct={queryProduct}
          authActions={authActions}
          loginDetails={loginDetails}
          source={source}
          viewType={viewType}
          history={history}
        />
      </div>
    );
  }
}

export function authRedirectHandler(location, options) {
  const { userDetails } = options || {};
  const redirect = get(location, 'query.redirect') || '/';
  const redirectWithoutUtmParams = excludeUtmParamsFromUrl(redirect);
  const performRedirectWithUserData = parseBoolean(
    get(location, ['query', PERFORM_REDIRECT_WITH_USER_DATA]),
  );

  // Just pass back a minimal amount of user data if required to do so
  if (performRedirectWithUserData) {
    const parsedRedirectDescriptor = url.parse(
      redirectWithoutUtmParams,
      qs.parse,
    );
    parsedRedirectDescriptor.query = parsedRedirectDescriptor.query || {};

    parsedRedirectDescriptor.query.name = userDetails.title;
    parsedRedirectDescriptor.query.email = userDetails.email;

    // Omitting as we want to recreate the query params from the query object above.
    const finalRedirectDescriptor = omit(parsedRedirectDescriptor, [
      'href',
      'path',
      'search',
    ]);
    return clientRedirect(url.format(finalRedirectDescriptor));
  }

  return url.parse(redirectWithoutUtmParams);
}

function mapStateToProps(state) {
  const productsObj = get(state, 'products', {});

  return { productsObj };
}

export default flow(
  connect(mapStateToProps),
  withAuthViewHandling({ isFullPage: true }),
  withAuthHandling({ authRedirectHandler }),
)(AuthPage);
