import isServer from './isServer';

export default function clientRedirect(url, retainHistory = false) {
  if (isServer()) {
    return;
  }

  if (retainHistory) {
    window.location.href = url;
    return;
  }

  window.location.replace(url);
}
